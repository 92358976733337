import * as React from 'react';

import { Container, Grid, GridItem, Icon } from '@ao-internal/components-react';

import ArticleGrid from '../components/ArticleGrid';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import { maybePluralise } from '../helpers/helpers';

export interface EventsTemplateProps {
	pageContext: {
		events: {
			node: WPEvent;
		}[];
		totalEventCount: number;
		totalEventPages: number;
		currentPage: number;
	};
	data: {
		wp: {
			generalSettings: {
				description: string;
			};
		};
	};
}

export const pageQuery = graphql`
	query eventsPageQuery {
		wp {
			generalSettings {
				description
			}
		}
	}
`;

export class EventsTemplate extends React.PureComponent<
	EventsTemplateProps,
	{}
> {
	render(): React.ReactNode {
		const {
			currentPage,
			totalEventPages,
			totalEventCount,
		} = this.props.pageContext;
		const isFirst = currentPage === 1;
		const isLast = currentPage === totalEventPages;
		const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString();
		const nextPage = (currentPage + 1).toString();

		return (
			<React.Fragment>
				<Helmet title="AO Tech Events"></Helmet>
				<div className="blog-archive">
					<Container className="px-0 mt-6 sub-header">
						<Grid className="items-center">
							<GridItem key="1" span={{ def: 12, md: 6 }}>
								<h1 className="text-display md:text-display-lg">
									Events we're attending
								</h1>
							</GridItem>
							<GridItem
								key="2"
								span={{ def: 12, md: 6 }}
								className="text-right">
								<span>
									{maybePluralise(totalEventCount, 'Event', 'Events')}
								</span>
							</GridItem>
						</Grid>
					</Container>

					<ArticleGrid
						events={this.props.pageContext.events.map((post) => post.node)}
					/>

					<div className="text-center m-6 blog-archive__pagination">
						<div className="c-pagination">
							{!isFirst && (
								<Link
									to={'/events/' + prevPage}
									rel="prev"
									className="c-pagination-prev">
									<Icon icon="chevron-left" label="Previous page" />
								</Link>
							)}

							{Array.from({ length: totalEventPages }, (_, i) => (
								<Link
									key={`pagination-number${i + 1}`}
									to={`/events/${i === 0 ? '' : i + 1}`}
									className={
										currentPage === i + 1
											? 'c-pagination-link c-pagination-link--active'
											: 'c-pagination-link'
									}>
									{i + 1}
								</Link>
							))}

							{!isLast && (
								<Link
									to={'/events/' + nextPage}
									rel="next"
									className="c-pagination-next">
									<Icon icon="chevron-right" label="Next page" />
								</Link>
							)}
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default function (props: EventsTemplateProps) {
	return (
		<Layout>
			<EventsTemplate {...props} />
		</Layout>
	);
}
